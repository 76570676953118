import FlatpickrController from "./flatpickr_controller"
import { format, parse } from "fecha"
import { template } from "lodash-es"
import { isSameDay, numberOfNightsBetween, isInRange } from "../utils/dates"

export default class extends FlatpickrController {
  static targets = [
    "adultGuestsInput",
    "childrenGuestsInput",
    "checkInInput",
    "checkOutInput",
    "hotDataTemplate",
    "hotData",
    "availabilityLoader",
    "priceLoader",
    "capacityExceededAlert"
  ]

  inputFieldsDateFormat = "YYYY-MM-DD"

  connect () {
    this.hotDataTemplate = template(this.hotDataTemplateTarget.innerHTML)
    this.availablePeriods = this.#parseAvailablePeriods
    this.occupationalRuleSeasons = this.#parseOccupationalRuleSeasons

    const initialDates = [
      parse(this.checkInInputTarget?.value, this.inputFieldsDateFormat),
      parse(this.checkOutInputTarget?.value, this.inputFieldsDateFormat)
    ]

    this.config = { mode: "range", defaultDate: initialDates, enable: [this.isAvailable.bind(this)] }
    super.connect()
    this.peopleCapacity = parseInt(this.data.get("people-capacity"))
    if (initialDates.length) this.change(initialDates)
  }

  change ([checkInDate, checkOutDate]) {
    if (checkInDate && this.hasCheckInInputTarget) this.checkInInputTarget.value = format(checkInDate, this.inputFieldsDateFormat)
    if (checkOutDate && this.hasCheckOutInputTarget) this.checkOutInputTarget.value = format(checkOutDate, this.inputFieldsDateFormat)

    if (checkInDate && checkOutDate) {
      this.#callHook(this.onDateSelection)
    } else {
      this.#callHook(this.onInvalidForm)
    }
  }

  checkPeopleCapacity () {
    if (this.#isCapacityExceeded) {
      this.#callHook(this.onInvalidForm)
      this.capacityExceededAlertTarget.hidden = false
    } else {
      this.capacityExceededAlertTarget.hidden = true
      this.#updatePrice()
    }
  }

  // CALENDAR DATES ENABLED/AVAILABLE

  dayCreate (selectedDates, _label, _fp, dayElem) {
    const [checkInDate, checkOutDate] = selectedDates
    const date = dayElem.dateObj

    if (isSameDay(date, checkInDate)) return
    if (isSameDay(date, checkOutDate)) return

    if (!this.isSelectable(date, selectedDates)) dayElem.classList.add("notSelectable")
  }

  isAvailable (date) {
    return this.availablePeriods.some(availablePeriod => isInRange(date, ...availablePeriod))
  }

  isSelectable (date, [checkInDate, checkOutDate]) {
    if (checkInDate && !checkOutDate) { // user has already selected a date and is choosing another to make a range
      if (date < checkInDate) { // if user selects this `date`, they will have selected their check-out date prior to selecting their check-in date
        return this.#isSelectableForCheckIn(date) && this.#isSelectableForCheckOut(checkInDate, date)
      } else { // user selected their dates in order: check-in date, then check-out date
        return this.#isSelectableForCheckOut(date, checkInDate)
      }
    } else { // user has already selected either no date or 2 dates
      return this.#isSelectableForCheckIn(date)
    }
  }

  #isSelectableForCheckIn (date) {
    const {
      checkInWeekDays = [],
      checkInMonthDays = []
    } = this.#findApplicableOccupationalRuleSeason(date) || {}

    return checkInWeekDays.includes(date.getDay()) || checkInMonthDays.includes(date.getDate())
  }

  #isSelectableForCheckOut (date, checkInDate) {
    const numberOfNights = numberOfNightsBetween(date, checkInDate)
    const {
      checkOutWeekDays = [],
      checkOutMonthDays = [],
      minimumNights,
      maximumNights
    } = this.#findApplicableOccupationalRuleSeason(date) || {}

    if (minimumNights && numberOfNights < minimumNights) return false
    if (maximumNights && numberOfNights > maximumNights) return false

    return checkOutWeekDays.includes(date.getDay()) || checkOutMonthDays.includes(date.getDate())
  }

  #findApplicableOccupationalRuleSeason (date) {
    return this.occupationalRuleSeasons.find(season => isInRange(date, season.startDate, season.endDate))
  }

  get #isCapacityExceeded () {
    return (this.peopleCapacity - parseInt(this.adultGuestsInputTarget.value) - parseInt(this.childrenGuestsInputTarget.value)) < 0
  }

  // HOT DATA REQUESTS

  #updatePrice () {
    if (!this.checkInInputTarget.value || !this.checkOutInputTarget.value) return

    this.#callHook(this.beforeAvailabilityRequest)

    const params = new URLSearchParams()
    params.append("locale", this.data.get("locale")) // `this.data.get(…)` is `stimulus-flatpickr`'s API to retrieve `data-flatpickr-*` attributes
    params.append("rental_accommodation_id", this.data.get("rental-accommodation-id")) // `this.data.get(…)` is `stimulus-flatpickr`'s API to retrieve `data-flatpickr-*` attributes
    params.append("arrival_date", format(parse(this.checkInInputTarget.value, this.inputFieldsDateFormat), "YYYY-MM-DD"))
    params.append("departure_date", format(parse(this.checkOutInputTarget.value, this.inputFieldsDateFormat), "YYYY-MM-DD"))
    params.append("adults_number", this.hasAdultGuestsInputTarget ? this.adultGuestsInputTarget.value || "1" : "1")
    params.append("children_number", this.hasChildrenGuestsInputTarget ? this.childrenGuestsInputTarget.value || "0" : "0")

    this.abortController?.abort()
    this.abortController = new AbortController()

    fetch(`/api/avantio/is_available.json?${params}`, { signal: this.abortController.signal })
      .then(response => response.json())
      .then(data => {
        if (data.available_code === "1") { // available
          this.#callHook(this.onAvailabilityRequestSuccess)

          this.abortController = new AbortController()

          fetch(`/api/avantio/get_booking_price.json?${params}`, { signal: this.abortController.signal })
            .then(response => response.json())
            .then(data => {
              this.#callHook(this.onPriceRequestSuccess, this.hotDataTemplate({
                    nightCount: data.humanized_night_count,
                    price: data.humanized_price
                  }))
                })
            .catch((err) => {
              // catch AbortError raised by abortController.abort() call
              if (err.name !== "AbortError") throw err
            })
        } else if (["-1", "-2", "-5", "-7", "-8"].includes(data.available_code)) {
          this.#callHook(this.onAvailabilityRequestContactSuggestion, `<span class="text-sm">${data.humanized_message}</span>`)
        } else {
          this.#callHook(this.onAvailabilityRequestError, `<span class="text-sm">${data.humanized_message}</span>`)
        }
      })
      .catch((err) => {
        // catch AbortError raised by abortController.abort() call
        if (err.name !== "AbortError") throw err
      })
  }

  // HOOKS

  onDateSelection () {
    if (this.hasCapacityExceededAlertTarget) {
      this.checkPeopleCapacity()
    } else {
      this.#updatePrice()
    }
  }

  onInvalidForm () {
    this.abortController?.abort()
    this.hotDataTarget.innerHTML = ""
  }

  beforeAvailabilityRequest () {
    this.hotDataTarget.innerHTML = this.availabilityLoaderTarget.innerHTML
  }

  onAvailabilityRequestSuccess () {
    this.hotDataTarget.innerHTML = this.priceLoaderTarget.innerHTML
  }

  onPriceRequestSuccess (message) {
    this.hotDataTarget.innerHTML = message
  }

  onAvailabilityRequestContactSuggestion (message) {
    this.hotDataTarget.innerHTML = message
  }

  onAvailabilityRequestError (message) {
    this.hotDataTarget.innerHTML = message
  }

  #callHook (callback, ...args) {
    if (typeof callback === typeof Function) callback.bind(this)(args)
  }

  // ATTRIBUTES PARSING

  get #parseAvailablePeriods () {
    return JSON.parse(this.data.get("available-periods") || "[]").map(
      ([startDate, endDate]) => [parse(startDate, "YYYY-MM-DD"), parse(endDate, "YYYY-MM-DD")]
    )
  }

  get #parseOccupationalRuleSeasons () {
    return JSON.parse(this.data.get("occupational-rule-seasons") || "[]").map(
      ({ startDate, endDate, ...otherAttributes }) => {
        return { startDate: parse(startDate, "YYYY-MM-DD"), endDate: parse(endDate, "YYYY-MM-DD"), ...otherAttributes }
      }
    )
  }
}
