const isSameDay = (date1, date2) => (
  date1?.getUTCDate() === date2?.getUTCDate() &&
  date1?.getUTCMonth() === date2?.getUTCMonth() &&
  date1?.getUTCFullYear() === date2?.getUTCFullYear()
)

const numberOfNightsBetween = (date1, date2) => {
  // Discard the time and time-zone information to prevent daylight saving time changes
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())

  return Math.abs(Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24)))
}

const nextDay = (date) => {
  const nextDate = new Date(date)
  nextDate.setDate(date.getDate() + 1)
  return nextDate
}

const previousDay = (date) => {
  const previousDate = new Date(date)
  previousDate.setDate(date.getDate() - 1)
  return previousDate
}

const isInRange = (date, rangeStart, rangeEnd) => (
  date >= rangeStart && date <= rangeEnd
)

const firstSaturdayAfter = date => new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate() + (6 - date.getDay())
)

export {
  isSameDay,
  numberOfNightsBetween,
  isInRange,
  nextDay,
  previousDay,
  firstSaturdayAfter
}
