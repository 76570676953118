import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["focus"]

  connect () {
    this.isOpen = false
  }

  clickOutside (e, options = {}) {
    if (this.isOpen && !this.element.contains(e.target)) {
      if (options.submit) this._submitForm()
      this.element.classList.remove("active")
      this.isOpen = false
    }
  }

  clickOutsideAndSubmit (e) {
    this.clickOutside(e, { submit: true })
  }

  toggleActive () {
    this.element.classList.toggle("active", !this.isOpen)
    if (this.hasFocusTarget && !this.isOpen) this.focusTarget.focus()
    this.isOpen = !this.isOpen
  }

  _submitForm () {
    const form = this.element.closest("form")
    if (!form) return

    if (form.dataset.remote === "true") {
      Rails.fire(form, "submit")
    } else {
      form.submit()
    }
  }
}
