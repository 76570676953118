import { Controller } from "@hotwired/stimulus"

// Initialize a Cleave.js instance on the input it's attached to.
//
// The Cleave.js instance is configured with format from `window.I18n` if available.
//
// The raw (unformatted) value is stored in a hidden field created after the original
// input and whose `name` attribute is similar (original field is removed its `name`
// attribute to ensure it's not submitted with the form).
//
export default class extends Controller {
  connect () {
    if (this.element.tagName === "INPUT") {
      this.createHiddenInput()
      this.element.dataset.unformattedValue = this.element.value
      this.initializeCleave()
    } else {
      console.warn(`The ${this.identifier} Stimulus controller must be attached to an "input" element. Ignoring attempt to attach it to:`, this.element)
    }
  }

  disconnect () {
    if (this.cleave) this.cleave.destroy()
  }

  onValueChanged (event) {
    this.hiddenInput.value = event.target.rawValue
    this.element.dataset.unformattedValue = event.target.rawValue
  }

  createHiddenInput () {
    this.hiddenInput = document.createElement("input")
    this.hiddenInput.type = "hidden"
    this.hiddenInput.name = this.element.name
    this.element.after(this.hiddenInput)
    this.element.removeAttribute("name")
  }

  initializeCleave () {
    import("cleave.js").then(({ default: Cleave }) => {
      this.cleave = new Cleave(this.element, {
        numeral: true,
        numeralPositiveOnly: true,
        numeralDecimalScale: 0,
        delimiter: window.I18n?.number?.format?.delimiter,
        onValueChanged: this.onValueChanged.bind(this)
      })
    })
  }
}
