import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "modal"
  ]

  static values = {
    lastUpdatedAt: Number
  }

  connect () {
    const closedAt = localStorage.getItem("PopupClosedAt")
    const displayPopup = closedAt ? this.lastUpdatedAtValue > closedAt : true
    if (displayPopup) {
      setTimeout(() => {
        this.element.classList.remove("hidden", !displayPopup)
      }, "2000")
    }
  }

  hidePopup () {
    this.element.classList.add("hidden")
    localStorage.setItem("PopupClosedAt", Date.now())
  }
}
