export default class {
  constructor () {
    this.appHeight()
    this.bindEvents()
  }

  bindEvents () {
    window.addEventListener('resize', this.appHeight)
  }

  appHeight () {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    document.dispatchEvent(new CustomEvent("constantHeightChanged"))
  }
}
