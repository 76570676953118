import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    options: Object
  }

  connect () {
    import("swiper").then(({ default: Swiper, Navigation, Pagination, FreeMode }) => {
      this.swiper = new Swiper(this.element, {
        modules: [Navigation, Pagination, FreeMode],
        ...this.defaultOptions,
        ...this.optionsValue
      })
    })
  }

  disconnect () {
    if (!this.swiper) return
    this.swiper.destroy()
    this.swiper = undefined
  }

  get defaultOptions () {
    return {
      speed: 1050,
      freeMode: {
        enabled: false
      },
      slidesPerView: 4.2,
      spaceBetween: 10,
      grabCursor: true
    }
  }
}
