/* global L */

import { Controller } from "@hotwired/stimulus"

import "leaflet/dist/leaflet.css"
import "leaflet.markercluster/dist/MarkerCluster.css"

export default class extends Controller {
  static values = {
    markersData: Array,
    defaultCenter: Array,
    clustered: { type: Boolean, default: false },
    vague: { type: Boolean, default: false },
    scroll: { type: Boolean, default: false },
    notInitializeLeafletOnLoad: { type: Boolean, default: false }
  }

  iconSize = 48
  minPopupWidth = 50 // Leaflet default, can be overridden in child controllers
  maxPopupWidth = 300 // Leaflet default, can be overridden in child controllers
  autoPanPadding = [10, 10]

  connect () {
    import("leaflet").then(() =>
      Promise.all([
        import("leaflet.markercluster"),
        import("mapbox-gl-leaflet")
      ]).then(() => {
        // ensure Leaflet is initialized only once `--app-height` is set!
        // (even though, at this point, we already waited for the whole 1MB of MapboxGL JS to load…)
        if (!this.notInitializeLeafletOnLoadValue) {
          if (document.documentElement.style.getPropertyValue("--app-height")) {
            this.initializeLeaflet()
          } else {
            document.addEventListener("constantHeightChanged", this.initializeLeaflet.bind(this))
          }
        }
      })
    )
  }

  disconnect () {
    this.map.remove()
    super.disconnect()
  }

  initializeLeaflet () {
    setTimeout(() => {
      if (this.map) this.map.remove()

      this.map = L.map(this.element, { zoom: 12, maxZoom: 17, scrollWheelZoom: this.scrollValue })

      this.loadTiles()
      this.loadMarkers()
      this.setMapCenter()
    }, this.notInitializeLeafletOnLoadValue ? 0 : 2000)
  }

  get markers () {
    const markers = []
    this.map.eachLayer(layer => {
      if (typeof layer.getAllChildMarkers === "function") { // this layer is a cluster
        markers.push(...layer.getAllChildMarkers())
      } else if (layer instanceof L.Marker) { // this layer is a marker
        markers.push(layer)
      }
    })
    return [...new Set(markers)].sort() // sort and remove duplicates
  }

  get clusters () {
    const clusters = []
    this.map.eachLayer(layer => {
      if (typeof layer.getAllChildMarkers === "function") { // this layer is a cluster
        clusters.push(layer)
      }
    })
    return [...new Set(clusters)].sort() // sort and remove duplicates
  }

  createPinMarkerLayer ({ latitude, longitude, ...customOptions }) {
    return L.marker(
      { lat: latitude, lng: longitude },
      { icon: this.createMarkerIcon(), ...customOptions }
    )
  }

  createCircleMarkerLayer ({ latitude, longitude, color = "#3C3C3C" }) {
    return L.circle(
      { lat: latitude, lng: longitude },
      { color, opacity: 0.5, fillOpacity: 0.25, radius: 500 }
    )
  }

  createMarkerLayer ({ latitude, longitude, popupContentHtml, ...customOptions }) {
    if (this.vagueValue) {
      return this.createCircleMarkerLayer({ latitude, longitude })
    }

    const marker = this.createPinMarkerLayer({ latitude, longitude, ...customOptions })

    const defaultPopupOptions = { minWidth: this.minPopupWidth, maxWidth: this.maxPopupWidth, autoPanPadding: this.autoPanPadding }
    const popup = L.popup(defaultPopupOptions).setContent(popupContentHtml)

    marker.bindPopup(popup, { offset: L.point(0, -15) })
    marker.on("mouseover", _event => this.highlightMarker(marker))
    return marker
  }

  loadMarkers () {
    if (this.clusteredValue) {
      this.loadClusteredMarkers()
    } else {
      this.loadFlatMarkers()
    }
  }

  loadFlatMarkers () {
    this.markersDataValue.forEach(markerData =>
      this.createMarkerLayer(markerData).addTo(this.map)
    )
  }

  loadClusteredMarkers () {
    this.markerClusterGroup = L.markerClusterGroup({
      showCoverageOnHover: false,
      maxClusterRadius: 40, // default 80
      iconCreateFunction: this.createClusterIcon.bind(this)
    })

    this.markerClusterGroup.addLayers(
      this.markersDataValue.map(markerData => this.createMarkerLayer(markerData))
    )

    this.markerClusterGroup.on("clustermouseover", event => this.highlightCluster(event.layer))

    this.map.addLayer(this.markerClusterGroup)
  }

  loadTiles () {
    L.mapboxGL({
      style: `mapbox://styles/${window.mapbox?.styleId}`,
      accessToken: window.mapbox?.accessToken
    }).addTo(this.map)
  }

  setMapCenter () {
    const latLngs = this.markersDataValue.map(({ latitude, longitude }) => [latitude, longitude])

    if (latLngs.length > 1) { // multiple pins
      this.map.fitBounds(latLngs, { padding: [50, 50] })
    } else if (latLngs.length === 1) { // exactly 1 pin
      this.map.setView(latLngs[0], this.vagueValue ? 14 : 16)
    } else if (this.defaultCenterValue) { // default city
      this.map.setView(this.defaultCenterValue, 14)
    } else { // france
      this.map.setView([47, 2], 6)
    }
  }

  resetAllMarkers () {
    // Flat markers
    this.markers.forEach(marker => {
      marker.setIcon(this.createMarkerIcon())
      marker.setZIndexOffset(0)
    })

    if (!this.clusteredValue) return

    // Cluster markers
    this.clusters.forEach(cluster => {
      cluster._highlighted = false
      cluster.setZIndexOffset(0)
    })
    this.markerClusterGroup.refreshClusters()
  }

  closeAllPopups () {
    this.markers.forEach(marker => marker.closePopup())
  }

  highlightMarker (marker) {
    if (this._highlightedMarker === marker) return
    this._highlightedMarker = marker

    this.resetAllMarkers()

    marker.setIcon(this.createMarkerIcon(true))
    marker.setZIndexOffset(9999)
    setTimeout(() => marker.openPopup(), 0) // `setTimeout` prevents a race condition
  }

  highlightCluster (cluster) {
    if (this._highlightedMarker === cluster) return
    this._highlightedMarker = cluster

    this.resetAllMarkers()
    this.closeAllPopups()

    cluster._highlighted = true
    cluster.setZIndexOffset(9999)
    this.markerClusterGroup.refreshClusters(cluster)
  }

  createMarkerIcon (highlighted = false) {
    const mainColor = highlighted ? "#B4002F" : "#3C3C3C"
    const shadowColor = "#343A40"
    const white = "#FFFFFF"

    const iconSvg = `
      <svg width="${this.iconSize}" height="${this.iconSize}" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle fill="${shadowColor}" fill-opacity="0.4" cx="35" cy="35" r="33"/>
        <circle fill="${mainColor}" stroke="${white}" stroke-width="7" cx="33" cy="33" r="29.5"/>
        <path fill="${white}" fill-rule="evenodd" clip-rule="evenodd" d="M22 46.9436C23.1552 46.5866 23.5885 45.6389 23.7674 44.3076C23.8164 43.9395 23.7701 24.5709 23.7701 22.6458C23.7701 21.1929 23.2585 19.9357 22.2742 19.7845H22.3434C24.2077 19.8532 25.3107 20.6857 25.4577 22.4956C25.6115 24.3705 25.6472 41.4469 25.2426 44.6646C25.0105 46.5078 24.1156 46.2905 22 46.9436ZM38.6202 46.4713C41.5455 44.9497 42.6001 40.4589 40.8278 37.1824C39.1626 34.1042 34.5327 32.7862 29.8757 33.9614V46.6586C32.4028 47.5857 36.4663 47.5894 38.6202 46.4713ZM29.8757 20.4107L29.8762 32.1627C33.3717 32.5318 36.5717 32.9438 38.8129 31.309C41.8729 29.0723 41.5598 23.7078 38.7155 21.3584C36.8629 19.8273 32.5534 18.9948 29.8757 20.4107ZM44.9584 39.1731C45.3976 44.141 42.3206 47.5297 37.564 47.8935C33.1119 48.2299 28.3036 47.6291 23.4406 47.9861C23.7488 47.3757 24.6852 47.646 25.3624 47.3239C27.4428 46.3328 26.8998 42.657 26.8998 39.2673V33.4875C26.8998 29.2231 27.3124 25.4547 26.8998 22.0222C26.6427 19.8855 25.3065 19.3994 23.3453 19.1783C30.8036 19.1995 39.6061 17.8223 42.7502 22.4009C43.5391 23.5502 44.1183 25.5404 43.9027 27.6105C43.5876 30.6437 41.4922 32.2039 38.9071 33.2035C42.237 33.7213 44.6677 35.8766 44.9584 39.1731Z"/>
      </svg>
    `

    return L.divIcon({
      html: iconSvg,
      className: "flex justify-center",
      iconAnchor: [this.iconSize / 2, this.iconSize / 2],
      iconSize: [this.iconSize, this.iconSize]
    })
  }

  // Based on `Leaflet.markercluster` default implementation:
  // https://github.com/Leaflet/Leaflet.markercluster/blob/v1.5.3/src/MarkerClusterGroup.js#L820-L834
  //
  createClusterIcon (clusterLayer) {
    const childCount = clusterLayer.getChildCount()
    let wrapperClass = "text-white text-lg bg-clip-padding rounded-full"
    wrapperClass += clusterLayer._highlighted ? " bg-primary-500" : " bg-grey-700"

    return new L.DivIcon({
      html: `
        <div
          class="text-center rounded-full"
          style="
            line-height: ${this.iconSize}px;
            width: ${this.iconSize}px;
            height: ${this.iconSize}px;
          "
        >${childCount}</div>
      `,
      iconSize: new L.Point(this.iconSize, this.iconSize),
      className: wrapperClass
    })
  }
}
