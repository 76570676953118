import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

/**
 * Automatically submit the closest form when the `submit` action is triggered.
 *
 * @example Basic usage
 *     <input data-controller="autosubmit" data-action="change->autosubmit#submit" />
 * @example Usage with `continue` param
 *     <input data-controller="autosubmit" data-action="change->autosubmit#submit" data-autosubmit-continue-param="true" />
 */
export default class extends Controller {
  submit (event) {
    const form = event.target.closest("form")

    if (event.params.continue) {
      form.insertAdjacentHTML("beforeend", `
        <input type="hidden" name="continue" value="true" />
      `)
    }

    if (form.dataset.remote === "true") {
      Rails.fire(form, "submit")
    } else {
      form.submit()
    }
  }
}
