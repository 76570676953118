import AvantioFlatpickrController from "./avantio_flatpickr_controller"

export default class extends AvantioFlatpickrController {
  static targets = super.targets.concat([
    "submitButton"
  ])

  inputFieldsDateFormat = "DD/MM/YYYY"

  connect () {
    this.bookingUrl = this.data.get("booking-url")
    this.contactUrl = this.data.get("contact-url")
    this.bookingSubmitButtonText = this.data.get("booking-submit-button-text")
    this.contactSubmitButtonText = this.data.get("contact-submit-button-text")

    super.connect()
  }

  onInvalidForm (...args) {
    super.onInvalidForm(args)

    this.#resetForm(args)
  }

  beforeAvailabilityRequest (...args) {
    super.beforeAvailabilityRequest(args)

    this.#resetForm()
  }

  onPriceRequestSuccess (...args) {
    super.onPriceRequestSuccess(args)

    this.element.closest("form").action = this.bookingUrl
    this.submitButtonTarget.value = this.bookingSubmitButtonText
    this.submitButtonTarget.classList.remove("hidden")
  }

  onAvailabilityRequestContactSuggestion (...args) {
    super.onAvailabilityRequestContactSuggestion(args)

    if (this.contactUrl) {
      this.element.closest("form").action = this.contactUrl
    }
    if (this.submitButtonTarget) {
      this.submitButtonTarget.value = this.contactSubmitButtonText
      this.submitButtonTarget.classList.remove("hidden")
    }
  }

  onAvailabilityRequestError (...args) {
    super.onAvailabilityRequestError(args)

    this.#resetForm()
  }

  #resetForm () {
    this.element.closest("form").action = ""
    if (this.submitButtonTarget) {
      this.submitButtonTarget.classList.add("hidden")
    }
  }
}
