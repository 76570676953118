import { Controller } from "@hotwired/stimulus"

// Dynamically add `target="_blank"` and `rel="noopener noreferrer"` to the attached anchor tag (`<a>`)
// If the `condition` value is provided, it must be a media query:
//   * when it matches, the attributes are added
//   * when it does not match, nothing happens
//
export default class extends Controller {
  static values = {
    condition: {
      type: String, // a media query is expected
      default: "(min-width: 0px)" // always matches
    }
  }

  connect () {
    if (this.element.tagName !== "A") throw new Error(`The ${this.identifier} Stimulus controller must be attached to an anchor (<a>) tag`)
    if (window.matchMedia(this.conditionValue).matches) {
      this.element.target = "_blank"
      this.element.rel = "noopener noreferrer"
    }
  }
}
