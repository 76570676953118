import LeafletController from "./leaflet_controller"
import "./../../stylesheets/application/components/override-vendors/_search_map_leaflet_override.sass"

export default class extends LeafletController {
  static targets = ["item"]
  minPopupWidth = 300
  maxPopupWidth = 300

  displayMap () {
    this.itemTarget.classList.remove("hidden")
  }

  initializeLeaflet () {
    setTimeout(() => {
      super.initializeLeaflet()

      this.boundOnHighlightSearchResult = this.onHighlightSearchResult.bind(this)
      document.addEventListener("highlight-search-result", this.boundOnHighlightSearchResult)

      this.boundOnShowMap = this.onShowMap.bind(this)
      document.addEventListener("show-search-map", this.boundOnShowMap)
    }, this.notInitializeLeafletOnLoadValue ? 0 : 2000)
  }

  disconnect () {
    super.disconnect()
    document.removeEventListener("highlight-search-result", this.boundOnHighlightSearchResult)
  }

  findAccommodationMarker (accommodationId) {
    return this.markers.find(marker =>
      marker.options.accommodationId === accommodationId
    )
  }

  onHighlightSearchResult (event) {
    const marker = this.findAccommodationMarker(event.detail.accommodationId)
    if (!marker) {
      console.warn("Leaflet marker not found for:", event.detail)
      return
    }

    if (this.clusteredValue) { // clustered map
      const visibleParent = this.markerClusterGroup.getVisibleParent(marker)

      if (visibleParent === marker) { // flat marker on clustered map
        this.highlightMarker(marker)
      } else { // cluster marker
        this.highlightCluster(visibleParent)
        this.map.panInside(visibleParent.getLatLng(), { padding: this.autoPanPadding })
      }
    } else { // flat map
      this.highlightMarker(marker)
    }
  }

  onShowMap (_event) {
    this.initializeLeaflet()
  }
}
