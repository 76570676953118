import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

import ScrollReveal from "stimulus-scroll-reveal"
import Reveal from "stimulus-reveal-controller"
import NestedForm from "stimulus-rails-nested-form"
import ContentLoader from "stimulus-content-loader"
import VisibilityController from "./visibility_controller"

import { pushState } from "../utils/stimulus_action_options"

const stimulus = Application.start()
stimulus.debug = process.env.NODE_ENV !== "production"

registerControllers(stimulus, import.meta.globEager('./*_controller.js'))

stimulus.register("scroll-reveal", ScrollReveal)
stimulus.register("reveal", Reveal)
stimulus.register("nested-form", NestedForm)
stimulus.register("content-loader", ContentLoader)
stimulus.register("visibility", VisibilityController)

stimulus.registerActionOption(...pushState())
