import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["map", "list"]

  toggleTargets (event) {
    if (this.mapTarget.classList.contains("hidden")) {
      this.mapTarget.classList.remove("hidden")
      this.listTarget.classList.add("hidden")
    } else if (this.listTarget.classList.contains("hidden")) {
      this.listTarget.classList.remove("hidden")
      this.mapTarget.classList.add("hidden")
    }
  }
}
