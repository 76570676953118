import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]

  elementTargetConnected (element) {
    this.push({ params: JSON.parse(element.dataset.dataLayerPayload) })
    element.remove()
  }

  push ({ params }) {
    if (window.dataLayer && params) window.dataLayer.push(params)
    if (this.application.debug) console.table(window.dataLayer)
  }
}
