import Lightbox from "stimulus-lightbox"

export default class extends Lightbox {
  get defaultOptions () {
    return {
      licenseKey: '2168F75C-C1C2-45FD-8C40-9193A7B8C3AA',
      thumbnail: false,
      animateThumb: false,
      showThumbByDefault: false,
      mode: 'lg-fade',
      cssEasing: 'cubic-bezier(.4, 0,.2,1)',
      speed: 1000,
      download: false,
      mobileSettings: {
        controls: true,
        showCloseIcon: true
      },
      selector: '.item'
    }
  }
}
