import FlatpickrController from "./flatpickr_controller"
import { isInRange, previousDay, firstSaturdayAfter } from "../utils/dates"
import { format } from "fecha"

export default class extends FlatpickrController {
  static values = { autosubmit: { type: Boolean, default: false } }
  static targets = ["checkInInput", "checkOutInput"]

  connect () {
    this.seasons = JSON.parse(this.data.get("seasons") || "{}")
    this.seasonsConfigured = !!(this.seasons.low_season?.start_day &&
                                this.seasons.low_season?.start_month &&
                                this.seasons.peak_season?.start_day &&
                                this.seasons.peak_season?.start_month)

    this.config = { mode: "range" }
    super.connect()
  }

  change ([checkInDate, checkOutDate]) {
    if (checkInDate && this.hasCheckInInputTarget) this.checkInInputTarget.value = format(checkInDate, "YYYY-MM-DD")
    if (checkOutDate && this.hasCheckOutInputTarget) this.checkOutInputTarget.value = format(checkOutDate, "YYYY-MM-DD")

    if (checkInDate && checkOutDate && this.autosubmitValue) this._submitClosestForm()
  }

  onClearButtonClick (event) {
    if (this.hasCheckInInputTarget) this.checkInInputTarget.value = null
    if (this.hasCheckOutInputTarget) this.checkOutInputTarget.value = null
    this.fp.clear() // triggers a form submit
  }

  dayCreate ([_checkInDate, _checkOutDate], _label, _fp, dayElem) {
    if (this.seasonsConfigured && this.isPeakSeason(dayElem.dateObj) && dayElem.dateObj.getDay() !== 6) {
      dayElem.classList.add("notSelectable")
    }
  }

  isPeakSeason (date) {
    const yearLowSeasonStartDate = firstSaturdayAfter(new Date(date.getFullYear(), this.seasons.low_season.start_month - 1, this.seasons.low_season.start_day))
    const yearPeakSeasonStartDate = firstSaturdayAfter(new Date(date.getFullYear(), this.seasons.peak_season.start_month - 1, this.seasons.peak_season.start_day))

    if (yearPeakSeasonStartDate < yearLowSeasonStartDate) {
      return isInRange(date, yearPeakSeasonStartDate, previousDay(yearLowSeasonStartDate))
    } else {
      return date < yearLowSeasonStartDate || date >= yearPeakSeasonStartDate
    }
  }

  _submitClosestForm () {
    const form = this.element.closest("form")

    if (form.dataset.remote === "true") {
      Rails.fire(form, "submit")
    } else {
      form.submit()
    }
  }
}
