// MODULES =====================================================================
import Rails from "@rails/ujs"

import 'intersection-observer'
import ConstantHeight from "../scripts/utils/constant-height"
import FlashMessage from "../scripts/utils/flash-messages"
import executeRecaptcha from "../scripts/utils/grecaptcha"

// Stimulus
import "../scripts/controllers"

// CONFIGURATION ===============================================================
if (!window._rails_loaded) { Rails.start() }

window.FlashMessage = FlashMessage
window.Rails = Rails
window.executeRecaptcha = executeRecaptcha

document.addEventListener("DOMContentLoaded", () => {
  new ConstantHeight()
})
